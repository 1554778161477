import axios from '@/plugins/axios.js'

// selectSite
export const selectSite = (params) => axios.post('/tb-site/selectSite', params)

// 查询
export const siteSearch = (params) => axios.post('/tb-site/selectDeviceDetail', params)

// 模板导出
export const siteTemplateExport = () => axios.get('/tb-site/downloadSite', { responseType: 'blob' })

// 导入失败数据导出
export const siteExportFailedData = (params) => {
  return axios.post('/tb-site/exportFailedData', params, { responseType: 'blob' })
}

// 移动
export const moveSite = (params) => {
  return axios.post(
    '/tb-site/move',
    {
      id: params.id,
    },
    {
      params: {
        ids: params.ids.join(','),
      },
    }
  )
}

// 新增
export const addSite = (params) => axios.post('/tb-site/addSite', params)

// 修改
export const updateSite = (params) => axios.post('/tb-site/updateSite', params)

// 批量删除
export const removeSiteBatch = (data) => axios.delete('/tb-site/deleteSite', { data })

// 查询区域下的数据
export const transferSite = (params) => axios.post('/tb-site/transferSite', params)

// site下拉列表(数据权限)
export const selectSitePermission = () => axios.get('/tb-site/allSite')
