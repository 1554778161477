<template>
  <div class="page">
    <div class="page-header footer-btns">
      <el-button class="footer-btn"
        type="primary"
        icon="el-icon-plus"
        @click="addItem()"
        v-has="'add'"
      >{{ $t('ssite.addSite') }}</el-button>
      <el-button class="footer-btn"
        type="primary"
        plain
        icon="el-icon-plus iconfont icon-import"
        @click="importItem"
        v-has="'import'"
      >{{ $t('ssite.importSite') }}</el-button>
    </div>
    <div class="page-main">
      <div class="site-left">
        <div class="site-query">
          <el-input
            v-model="filterText"
            :placeholder="$t('ssite.keywordsPla')"
            prefix-icon="el-icon-search"
            clearable
          ></el-input>
        </div>
        <div class="site-tree">
          <el-tree
            ref="tree"
            size="large"
            :data="data"
            :props="defaultProps"
            @node-click="handleNodeClick"
            :filter-node-method="filterNode"
            :expand-on-click-node="false"
            default-expand-all
            highlight-current
            node-key="id"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <el-tooltip
                disabled
                effect="dark"
                :content="node.label"
                placement="top"
                :open-delay="1000"
              >
                <span class="node-title">
                  <div class="site-cell">
                    <span class="site-name">{{ node.label }}</span>
                    <span>({{ data.childDeviceCount }})</span>
                  </div>
                </span>
              </el-tooltip>
              <span class="node-options">
                <el-tooltip
                  effect="light"
                  :content="$t('edit')"
                  placement="top"
                  :open-delay="1000"
                  v-has="'edit'"
                >
                  <el-button type="text" class="btn-p0" @click="editItem(data)">
                    <i class="el-icon-edit iconfont icon-edit"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  effect="light"
                  :content="$t('add')"
                  placement="top"
                  :open-delay="1000"
                  v-has="'add'"
                >
                  <el-button type="text" class="btn-p0" @click="addItem(data.id)">
                    <i class="el-icon-circle-plus-outline"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  v-if="!data.isDefault"
                  effect="light"
                  :content="$t('delete')"
                  placement="top"
                  :open-delay="1000"
                  v-has="'delete'"
                >
                  <el-button
                    type="text"
                    class="btn-p0"
                    @click="deleteItem(data.id, data)"
                  >
                    <i class="el-icon-delete"></i>
                  </el-button>
                </el-tooltip>
              </span>
            </span>
          </el-tree>
        </div>
      </div>
      <div class="site-right">
        <!-- 话机详情 -->
        <site-details :req="siteData" @move="move"></site-details>
      </div>
    </div>
    <!-- 新建站点 -->
    <dialog-site-details
      v-if="siteDetailsVisible"
      :visible.sync="siteDetailsVisible"
      :selectList="selectList"
      :siteData="siteDetailsData"
      :treeData="siteDetailsData.id ? editData : data"
      @submit="getData"
    ></dialog-site-details>
    <!-- 删除站点 -->
    <DialogDelete
      v-if="dialogDeleteVisible"
      :visible.sync="dialogDeleteVisible"
      :treeData="data"
      :siteData="dialogDeleteSiteData"
      @submit="getData"
    ></DialogDelete>
    <!-- 移动话机 -->
    <dialog-move
      v-if="dialogMoveVisible"
      :visible.sync="dialogMoveVisible"
      :selectList="selectList"
      :treeData="moveData"
      :siteId="siteData.id"
      :hasAssigned="hasAssigned"
      @submit="getData"
    ></dialog-move>
    <!-- 导入 -->
    <dialog-import-item
      :visible.sync="importItemVisible"
      url="/tb-site/uploadSite"
      :title="$t('ssite.importSite')"
      @submit="getData"
      generalTemplateBtnShow
      :generalTemplateBtnText="$t('ssite.siteTemplate')"
      @generalTemplateclick="exportSiteTemp"
      msgTableShow
      :msgTable="importItemMsgTable"
      @msgTableExport="importItemMsgTableExport"
      @close="importItemVisible = false"
    >
      <!-- <div slot="generalTemplate" class="import-temp-wrap">
        <p class="tip">{{ $t('ssite.importTip') }}</p>
      </div> -->
    </dialog-import-item>
  </div>
</template>

<script>
import _ from 'lodash'
import SiteDetails from './siteDetails'
import DialogSiteDetails from './dialog-site-details'
import DialogMove from './dialog-move'
import DialogDelete from './dialog-delete'
import { DialogImportItem } from '@/components'
import { selectSite, siteTemplateExport, transferSite, removeSiteBatch, siteExportFailedData } from '@/api/ssite.js'
import { downloadFile } from '@/plugins/methods'

export default {
  name: 'Site',
  components: {
    DialogImportItem,
    SiteDetails,
    DialogSiteDetails,
    DialogMove,
    DialogDelete
  },
  data() {
    return {
      siteDetailsVisible: false,
      siteDetailsData: {},
      dialogMoveVisible: false,
      hasAssigned: false,
      filterText: '',
      data: [],
      backUpData: [],
      editData: [],
      defaultProps: {
        children: 'childSite',
        label: 'siteName',
      },
      siteData: {},
      selectList: [],
      // 导入
      importItemVisible: false,
      importItemMsgTable: {
        title: this.$t('ssite.importSite'),
        columnData: [
          {
            label: this.$t('ssite.siteName'),
            prop: 'siteName',
            minWidth: 100,
          },
          {
            label: this.$t('failedReason'),
            prop: 'failReason',
            minWidth: 200,
          },
        ],
      },
      // 删除
      dialogDeleteVisible: false,
      dialogDeleteSiteData: {}
    }
  },
  created() {
    this.getData()
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },
  methods: {
    // 获取数据
    getData() {
      selectSite()
        .then((res) => {
          if (res.data.code == 0) {
            this.data = res.data.result.rows
            this.backUpData = _.cloneDeep(this.data)
            this.siteData = this.data[0] ? this.data[0] : {}
            this.$nextTick(() => {
              this.$refs.tree.setCurrentKey(this.siteData.id)
            })
          }
        })
        .catch(() => {})
    },
    filterNode(value, data) {
      let keyword = value.toLowerCase(),
        siteName = data.siteName.toLowerCase(),
        siteDesc = data.siteDesc == null ? '' : data.siteDesc.toLowerCase()
      if (!value) return true
      return siteName.indexOf(keyword) !== -1 || siteDesc.indexOf(keyword) !== -1
    },
    handleNodeClick(data) {
      this.siteData = data
    },
    // 转移话机
    move(val) {
      this.selectList = val;
      this.hasAssigned = val.some(e => e.status != 0)
      this.dialogMoveVisible = true
    },
    addItem(parentId) {
      this.siteDetailsVisible = true
      this.siteDetailsData = {
        parentId,
      }
    },
    disableLeaf(id, list) {
      for (let item of list) {
        if (item.id == id) {
          item.disabled = true
          if (item.childSite && item.childSite.length) {
            item.childSite.forEach(child => {
              this.disableLeaf(child.id, item.childSite)
            })
          }
        } else {
          if (item.childSite && item.childSite.length) {
            this.disableLeaf(id, item.childSite)
          }
        }
      }
    },
    editItem(data) {
      this.editData = JSON.parse(JSON.stringify(this.data))
      this.disableLeaf(data.id, this.editData)
      this.siteDetailsVisible = true
      this.siteDetailsData = { ...data }
    },
    deleteItem(id, row) {
      transferSite([id]).then(({data}) => {
        if(data.message == '') {
          this.$confirm(
            this.$t('ssite.deleteTip', [row.siteName]),
            this.$t('tip'),
            {
              confirmButtonText: this.$t('confirm'),
              cancelButtonText: this.$t('cancel'),
              type: 'warning',
            }
          )
            .then(() => {
              removeSiteBatch({ ids: [id], args: [row.siteName] })
                .then((res) => {
                  this.$store.commit('session/SET_SIDEBAR_UPDATE', true)
                  this.$message.success(res.data.message)
                  this.getData()
                })
                .catch(() => {})
            })
            .catch(() => {})
        } else {
          this.data = _.cloneDeep(this.backUpData)
          this.disableLeaf(id, this.data)
          this.dialogDeleteVisible = true
          this.dialogDeleteSiteData = {
            ...row,
            message: data.message
          }
        }
      })
    },
    // 导入
    importItem() {
      this.importItemVisible = true
    },
    // 导入 - 导出模板
    exportSiteTemp() {
      siteTemplateExport().then((res) => {
        downloadFile(res)
      })
    },
    // 导入-导出错误信息
    importItemMsgTableExport(val) {
      siteExportFailedData(val).then((res) => {
        downloadFile(res)
      })
      this.importItemVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.page-main {
  padding: 0;
  background-color: transparent;
}
.footer-btns {
  display: inline ;
}
.site {
  height: 100%;
}
.site-left {
  box-sizing: border-box;
  float: left;
  height: 100%;
  width: 300px;
  background-color: #FFF;
  border-right: 1px solid $--border-color-base;
  .site-query {
    padding: 15px;
  }
  .site-tree {
    height: calc(100% - 84px);
    overflow: auto;
    .el-tree {
      background-color: #FFF;
    }
    ::v-deep .el-tree-node__content {
      position: relative;
      height: 56px;
      padding-left: 0 !important;
      &:hover {
        background-color: #F6F6F6;
        .node-options {
          display: inline-block;
        }
      }
      > .el-tree-node__expand-icon {
        padding-left: 6px 6px 6px 24px;
        font-size: 24px;
      }
    }
    ::v-deep .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
      background-color: #F6F6F6;
      color: $--color-primary;
    }
    ::v-deep .custom-tree-node {
      // position: inherit;
      font-size: 16px;
    }
  }
  .custom-tree-node {
    position: relative;
    display: block;
    width: calc(100% - 36px);
    font-size: 14px;
    .node-title {
      .site-cell {
        display: flex;
        align-items: center;
        width: calc(100% - 84px - 8px);
      }
      .site-name {
        display: inline-block;
        max-width: calc(100% - 1.5em);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .node-options {
      position: absolute;
      top: -2.5px;
      right: 10px;
      display: none;
      .el-button + .el-button {
        margin-left: 3px;
      }
    }
  }
}
.site-right {
  box-sizing: border-box;
  float: left;
  height: 100%;
  width: calc(100% - 300px);
  background-color: #FFF;
}
.import-temp-wrap {
  text-align: center;
  .tip {
    word-break: break-word;
  }
}
</style>
