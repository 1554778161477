<template>
  <el-dialog
    :title="$t('ssite.move')"
    :visible.sync="visible"
    width="960px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <span slot="footer">
      <el-button @click="handleClose">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submit" :disabled="disabled">{{ $t('save') }}</el-button>
    </span>
    <el-form
      class="add-form dialog-add-form"
      ref="form"
      :model="form"
      :rules="rules"
      label-width="150px"
    >
      <el-form-item :label="$t('ssite.siteName')" prop="siteId">
        <select-site v-model="form.siteId" :treeData="treeData"></select-site>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { moveSite } from '@/api/ssite'
import { SelectSite } from '@/components'

export default {
  name: 'dialog-move',
  components: {
    SelectSite,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    selectList: Array,
    treeData: Array,
    siteId: String,
    hasAssigned: Boolean,
  },
  data() {
    return {
      oldSiteId: this.siteId,
      form: {
        siteId: this.siteId,
      },
      rules: {
        siteId: {
          required: true,
          message: 'siteId',
          trigger: 'blur',
        },
      },
    }
  },
  computed: {
    disabled() {
      return this.oldSiteId == this.form.siteId
    }
  },
  methods: {
    // 取消
    handleClose() {
      this.$emit('update:visible', false)
    },
    move() {
      moveSite({
        id: this.form.siteId,
        ids: this.selectList.map((e) => e.id),
      }).then((res) => {
        this.$message.success(res.data.message)
        this.$emit('submit')
        this.$emit('update:visible', false)
      })
    },
    // 确定
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.hasAssigned) {
            this.$confirm(this.$t('ssite.moveTip'), this.$t('tip'), {
              confirmButtonText: this.$t('confirm'),
              cancelButtonText: this.$t('cancel'),
              type: 'warning',
            })
              .then(() => {
                this.move()
              })
              .catch(() => {})
          } else {
            this.move()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>
