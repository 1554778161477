<template>
  <el-dialog
    :title="isEdit ? $t('ssite.editSite') : $t('ssite.addSite')"
    :visible.sync="visible"
    width="960px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      class="add-form dialog-add-form"
      ref="form"
      :model="form"
      :rules="rules"
      label-width="150px"
    >
      <el-form-item :label="$t('ssite.siteName')" prop="siteName">
        <el-input
          v-model="form.siteName"
          :placeholder="$t('ssite.siteNamePal')"
          :maxlength="128"
        >
        </el-input>
      </el-form-item>
      <el-form-item v-if="!isDefaultSite" :label="$t('ssite.parentSite')" prop="parentId">
        <select-site
          v-model="form.parentId"
          :treeData="treeData"
          :disabled="disabled"
          defaultShow
          @change="siteChange"
        ></select-site>
      </el-form-item>
      <el-form-item :label="$t('ssite.description')">
        <el-input
          v-model="form.siteDesc"
          type="textarea"
          :rows="6"
          :placeholder="$t('ssite.descriptionPla')"
          :maxlength="500"
        ></el-input>
      </el-form-item>
    </el-form>
    <span class="footer-btns" slot="footer">
      <el-button class="footer-btn" type="primary" plain @click="handleClose">{{ $t('cancel') }}</el-button>
      <el-button class="footer-btn" type="primary" @click="submit">{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { SelectSite } from '@/components'
import { addSite, updateSite } from '@/api/ssite'

export default {
  name: 'dialog-site-details',
  components: {
    SelectSite,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    treeData: Array,
    siteData: Object,
    selectList: Array,
  },
  data() {
    let siteNameValidatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t('ssite.siteNameRule')))
      } else if (this.siteNameValidate.includes(value) && !this.form.id) {
        callback(new Error(this.$t('ssite.siteNameRule2')))
      } else {
        callback()
      }
    }
    const parentIdValidator = (rule, value, callback) => {
      if (value == this.form.id) {
        callback(new Error(this.$t('ssite.parentIdRule')))
      } else {
        callback()
      }
    }
    return {
      form: this.siteData,
      isEdit: this.siteData.id ? true : false,
      isDefaultSite: this.treeData[0].id == this.siteData.id,
      disabled: this.siteData.isDefault === 1 ? true : false,
      rules: {
        siteName: {
          required: true,
          validator: siteNameValidatePass,
          trigger: 'blur',
        },
        parentId: {
          required: true,
          validator: parentIdValidator
        },
      },
      siteNameValidate: [],
    }
  },
  methods: {
    // 取消
    handleClose() {
      this.$emit('update:visible', false)
    },
    // 站点变化
    siteChange(type, node) {
      let arr = []
      node.data.childSite &&
        node.data.childSite.forEach((e) => {
          arr.push(e.siteName)
        })
      this.siteNameValidate = arr
      type != 'init' && this.$refs.form.validateField('siteName')
    },
    // 确定
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const siteApi = this.isEdit ? updateSite : addSite
          const params = {
            siteName: this.form.siteName,
            parentId: this.form.parentId,
            siteDesc: this.form.siteDesc,
          }
          if (this.isEdit) params.id = this.form.id
          siteApi(params).then((res) => {
            this.$message.success(res.data.message)
            this.$store.commit('session/SET_SIDEBAR_UPDATE', true)
            this.$emit('submit')
            this.$emit('update:visible', false)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>
