<template>
  <div class="page site-table">
    <div class="page-header">
      <span class="page-header-left">
        {{ req.siteName }}
        <span v-show="req.isDefault">({{ $t('ssite.defaultSite') }})</span>
      </span>
      <el-button class="footer-btn" type="primary" @click="move" :disabled="!tableSelection.length">{{
        $t('ssite.move')
      }}</el-button>
    </div>
    <div class="page-main">
      <!-- 表格 -->
      <table-pagination
        memory="ssite"
        ref="refTable"
        :height="pageTabelHeight + 22"
        :tableData="tableData"
        :columnData="columnData"
        selectionShow
        @selection-change="selectionChange"
        columnOptionShow
        :total="tabelTotal"
        :currentPage.sync="tabelSearch.currentPage"
        :pageSize.sync="tabelSearch.pageSize"
        @changeCurrentPage="getTabelData"
        @sort-change="tabelSortChange"
      >
        <template slot="status" slot-scope="scope">
          <div :class="statusColor(scope.row.status)">{{siteAccountStatus[scope.row.status]}}</div>
        </template>
      </table-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { TablePagination } from '@/components'
import { siteSearch } from '@/api/ssite'

export default {
  name: 'siteDetails',
  components: {
    TablePagination,
  },
  props: ['req'],
  data() {
    return {
      tabelSearch: {},
      tabelTotal: 0,
      columnData: [
        {
          label: this.$t('device.macAddress'),
          prop: 'macAddress',
          minWidth: 200,
          sortable: 'custom',
          filter: 'toUpperCase',
        },
        {
          label: this.$t('device.deviceName'),
          prop: 'deviceName',
          minWidth: 200,
        },
        {
          label: this.$t('site.model'),
          prop: 'deviceModel',
          minWidth: 200,
          filterEnable: true,
        },
        {
          label: this.$t('site.SIP'),
          prop: 'status',
          minWidth: 200,
          slot: true,
        },
      ],
      tableData: [],
      tableSelection: [],
    }
  },
  computed: {
    ...mapGetters('dist', ['siteAccountStatus']),
    ...mapGetters('session', ['pageTabelHeight']),
    statusColor() {
      return function(status) {
        if (status == 1) {
          return "registered-color"
        } else if (status == 2) {
          return "unregistered-color"
        }
      }
    }
  },
  watch: {
    req(val) {
      this.tabelSearch = {
        id: val.id,
        currentPage: 1,
        pageSize: 10,
        sortField: 'deviceModel',
        sortOrder: 'desc',
      }
      this.getTabelData()
    },
  },
  methods: {
    // 获取数据
    getTabelData() {
      siteSearch(this.tabelSearch)
        .then(({ data }) => {
          this.tableData = data.result.rows // 表格数据
          this.tabelTotal = data.result.totalRows // 表格数据条目
          this.$refs.refTable.clearFilter() // 表格过滤清空
        })
        .catch(() => {})
    },
    // 排序
    tabelSortChange({ order, prop }) {
      this.tabelSearch.sortOrder = order ? order : 'desc'
      this.tabelSearch.sortField = order ? prop : 'deviceModel'
      this.getTabelData()
    },
    // 多选
    selectionChange(val) {
      this.tableSelection = val
    },
    // 转移话机
    move() {
      this.$emit('move', this.tableSelection)
    },
  },
}
</script>

<style lang="scss" scoped>
.site-table {
  padding: 0;
  height: calc(100% - 70px);
}
.page-header {
  background-color: #FFF;
  .page-header-left {
    width: calc(100% - 240px);
    color: #666;
    font-size: 20px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.registered-color {
  color: $--color-primary;
}
.unregistered-color {
  color: $theme-red;
}
::v-deep .page-table .table .el-table__header th {
  background-color: #F6F6F6;
}
</style>
