<template>
  <el-dialog
    :visible.sync="visible"
    width="650px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <h2 style="text-align: center;">{{ $t('ssite.deleteTip', [siteData.siteName]) }}</h2>
    <p style="font-size: 18px; word-break: keep-all;">{{ $t('ssite.deleteTip2', [siteData.message]) }}</p>
    <el-form
      class="add-form dialog-add-form"
      ref="form"
      :model="form"
      :rules="rules"
      label-width="auto"
    >
      <el-form-item :label="$t('ssite.site')" prop="siteId">
        <select-site v-model="form.siteId" :treeData="treeData"></select-site>
      </el-form-item>
      <el-form-item>
        <!-- <el-checkbox v-model="isIgnore">{{ $t('ignoreDuplicateData') }}</el-checkbox> -->
        {{ $t('ignoreTips') }}
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleClose">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submit">{{ $t('ok') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { removeSiteBatch } from '@/api/ssite'
import { SelectSite } from '@/components'

export default {
  name: 'dialog-move',
  components: {
    SelectSite,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    treeData: Array,
    siteData: Object
  },
  data() {
    return {
      form: {
        siteId: '',
      },
      rules: {
        siteId: {
          required: true,
          message: this.$t('ssite.siteRule'),
          trigger: 'blur',
        },
      },
      isIgnore: false,
    }
  },
  methods: {
    // 取消
    handleClose() {
      this.$emit('update:visible', false)
    },
    // 确定
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const params = {
            ids: [this.siteData.id],
            siteId: this.form.siteId,
            args: [this.siteData.siteName],
            isIgnore: this.isIgnore
          }
          removeSiteBatch(params)
            .then(({data}) => {
              this.$store.commit('session/SET_SIDEBAR_UPDATE', true)
              this.$message.success(data.message)
              this.$emit('submit')
              this.$emit('update:visible', false)
            })
            .catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>
